import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c7a80a5c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=c7a80a5c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7a80a5c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgSwiper: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/ImgSwiper.vue').default,SwiperProduct: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/SwiperProduct.vue').default,ViewMoreBtn: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/ViewMoreBtn.vue').default,Star: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Star.vue').default})
