//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "IndexPage",
  head() {
    if (this.headInfo.meta && this.headInfo.title) {
      return {
        title: this.headInfo.title,
        meta: [
          { hid: "description", name: "description", content: this.headInfo?.meta?.description },
          { property: "og:type", content: "website" },
          { hid: "keywords", name: "keywords", content: this.headInfo?.meta?.keywords },
        ],
        link: [
          { rel: "canonical", href: "https://www.velvethairextension.com/" },
          { rel: "alternate", media: "only screen and (max-width: 640px)", href: "https://m.velvethairextension.com/" },
        ],
      };
    } else {
      return {
        link: [
          { rel: "canonical", href: "https://www.velvethairextension.com/" },
          { rel: "alternate", media: "only screen and (max-width: 640px)", href: "https://m.velvethairextension.com/" },
        ],
      };
    }
  },
  async asyncData({ app }) {
    try {
      let homeDataRes = await app.$homeData();
      console.log(homeDataRes.data, "Home的数据");
      let firstSwiperData = [];
      let catalogSwiperData = []; //金刚区
      let headInfo = {};
      if (homeDataRes.code == 200000) {
        firstSwiperData = homeDataRes.data.indexConfigs?.indexTopBanner?.map((item) => {
          if (item.positionInfo != "" && item.positionInfo) {
            item.positionInfo = app.$toArrObj(item.positionInfo);
          } else {
            item.positionInfo = null;
          }
          return { ...item, imgUrl: item.image, size: "1800x750", preload: true };
        });
        catalogSwiperData = homeDataRes.data.indexConfigs?.indexJingangBanner?.map((item) => {
          if (item.positionInfo != "" && item.positionInfo) {
            item.positionInfo = app.$toArrObj(item.positionInfo);
          } else {
            item.positionInfo = null;
          }
          return { ...item, imgUrl: item.image, size: "305x434" };
        });
        headInfo = {
          title: homeDataRes.data.indexConfigs.indexMetaTitle,
          meta: {
            description: homeDataRes.data.indexConfigs.indexMetaDescription,
            keywords: homeDataRes.data.indexConfigs.indexMetaKeywords,
          },
        };
      }
      //产品列表
      let [newArrivalRes, recommendRes, homeBlogRes, videoRes] = await Promise.all([
        app.$products({
          category_id: homeDataRes.data?.indexConfigs?.indexNewArrivalCategory,
          page: 1,
          limit: 10,
        }),
        app.$products({
          category_id: homeDataRes.data?.indexConfigs?.indexRecommendCategory,
          page: 1,
          limit: 10,
        }),
        app.$homeBlog({
          page: 1,
          limit: 2,
        }),
        app.$homeVideo({
          video_ids: homeDataRes.data?.indexConfigs?.indexTiktokVideo,
        }),
      ]);
      //tiktok视频处理
      let tiktokGa = app.$toArrObj(homeDataRes.data?.indexConfigs?.indexTiktokVideoPositionInfo);
      let tiktokDataList = videoRes.data?.list.map((item) => {
        return {
          ...item,
          imgUrl: item.img,
          size: "750x750",
          // positionInfo: "event_label:ACTIVITY-Tiktok Hairstyle Inspiration,promotion_id:ACTIVITY-Tiktok Hairstyle Inspiration ",
          positionInfo: tiktokGa,
          options: {
            autoplay: false, // 是否自动播放
            muted: true, // 是否静音
            loop: true, // 是否开启循环播放
            preload: "auto", // 自动预加载
            language: "en", // 语言，'en', 'zh-cn', 'zh-tw'
            aspectRatio: "16:9", // 播放器高宽占比（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                type: "video/mp4", // 类型
                src: item.videoLink, // url地址
              },
            ],
            // poster: this.connectImgUrl(item.img), // 封面地址
            notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 是否显示当前时间和持续时间的分隔符，"/"
              durationDisplay: true, // 是否显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间
              fullscreenToggle: true, // 是否显示全屏按钮
            },
          },
        };
      });
      let tiktokData = {
        title: homeDataRes.data?.indexConfigs?.indexTiktokVideoTitle || "",
        titleSub: homeDataRes.data?.indexConfigs?.indexTiktokVideoTitleSub || "",
        detailTitle: homeDataRes.data?.indexConfigs?.indexTiktokVideoDetailContent,
        list: tiktokDataList || [],
      };
      let activityBannerarr = homeDataRes.data?.indexConfigs?.indexActivityBanner.map((item) => {
        if (item.positionInfo != "" && item.positionInfo) {
          item.positionInfo = app.$toArrObj(item.positionInfo);
        } else {
          item.positionInfo = null;
        }
        return { ...item };
      });
      let igDataList = homeDataRes.data?.indexConfigs?.indexIgImages.map((item) => {
        if (item.positionInfo != "" && item.positionInfo) {
          item.positionInfo = app.$toArrObj(item.positionInfo);
        } else {
          item.positionInfo = null;
        }
        return {
          ...item,
        };
      });
      return {
        firstSwiperData,
        catalogSwiperData,
        bestSaleData: {
          id: homeDataRes.data?.indexConfigs?.indexBestSellerCategory,
          list: homeDataRes.data?.bestSellerProduct?.list || [],
          title: homeDataRes.data?.indexConfigs?.indexBestSellerTitle,
        }, //best 产品
        effectData: {
          id: homeDataRes.data?.indexConfigs?.indexBeforeAfterComparisonCategory,
          list: homeDataRes.data?.comparisonProduct?.list || [],
          title: homeDataRes.data?.indexConfigs?.indexBeforeAfterComparisonTitle,
        }, //对比产品
        activityBanner: activityBannerarr, //活动banner
        newArrivalData: {
          id: homeDataRes.data?.indexConfigs?.indexNewArrivalCategory,
          list: newArrivalRes.data?.product?.list || [],
          title: homeDataRes.data?.indexConfigs?.indexNewArrivalTitle,
        }, //新品
        recommendData: {
          id: homeDataRes.data?.indexConfigs?.indexRecommendCategory,
          list: recommendRes.data?.product?.list || [],
          title: homeDataRes.data?.indexConfigs?.indexRecommendTitle,
        }, //recommendation
        brandData: {
          title: homeDataRes.data?.indexConfigs?.indexShowBannerTitle || "",
          content: homeDataRes.data?.indexConfigs?.indexShowBannerContent || "",
          imgUrl: homeDataRes.data?.indexConfigs?.indexShowBannerImage || "",
          imgAlt: homeDataRes.data?.indexConfigs?.indexShowBannerImageAlt || "",
          linktext: homeDataRes.data?.indexConfigs?.indexShowBannerLink || "/",
        }, //brand stroy
        igData: {
          title: homeDataRes.data?.indexConfigs?.indexIgTitle || "",
          titleSub: homeDataRes.data?.indexConfigs?.indexIgTitleSub || "",
          list: igDataList || [],
        }, //TAG US @unicehair TO BE FEATURED
        tiktokData, //tiktok
        homeBlogData: {
          title: "THE BLOG",
          subtext: "Heather instills powerful insights on her students across marketing, increasing bookings & revenue, and overcoming salon challenges.",
          list: homeBlogRes.data?.list || [],
        },
        headInfo,
      };
    } catch (error) {
      throw new Error("首页数据报错" + error);
    }
  },
  data() {
    let that = this;
    return {
      firstSwiperOption: {
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        autoplay: {
          delay: 10000,
        },
        speed: 1000,
        loop: that.firstSwiperData && that.firstSwiperData.length > 1,
        autoplayDisableOnInteraction: false,
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
        observer: true,
        observeParents: true,
      },
      effectSwiperOption: {
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: "1.2%",
        navigation: {
          nextEl: ".effect-swiper .effect-button-next",
          prevEl: ".effect-swiper .effect-button-prev",
        },
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
        observer: true,
        observeParents: true,
      },
      newArrivalsSwiperOption: {
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: "1.2%",
        navigation: {
          nextEl: ".newarrivals-swiper .newArrivals-button-next",
          prevEl: ".newarrivals-swiper .newArrivals-button-prev",
        },
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
        observer: true,
        observeParents: true,
      },
      recommendationSwiperOption: {
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: "1.2%",
        navigation: {
          nextEl: ".recommendation-swiper .recommendation-button-next",
          prevEl: ".recommendation-swiper .recommendation-button-prev",
        },
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
        observer: true,
        observeParents: true,
      },
      secSwiperOption: {
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: "1.2%",
        navigation: {
          nextEl: ".bestsellers-swiper .bestsellers-button-next ",
          prevEl: ".bestsellers-swiper .bestsellers-button-prev",
        },
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
      },
      // catalogSwiperOption: {
      //   slidesPerView: 1.29,
      //   spaceBetween: 10,
      //   on: {
      //     slideChangeTransitionEnd() {
      //       that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
      //     },
      //   },
      // },
      tiktokSwiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        autoplayDisableOnInteraction: false,
        on: {
          slideChangeTransitionEnd() {
            that.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
          },
        },
      },
      videoListData: {},
    };
  },
  created() {},
  mounted() {
    // console.log(this.homeDataRes, "tiktokData");
    // console.log(this.brandData, "brandData");
    this.videoListData = this.tiktokData;
    // this.$poplogin();
    // this.$popregister();
    if (this.$route.query.utm_source == "addtohome" && this.$cookies.get("addScreen") != 1) {
      this.$refs.homeScreenDialog.changeDialog();
    }
    console.log(this.igData.list, "igData.list");
  },
  methods: {
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-01 13:55:49
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 显示预览弹窗
     * @param {*} index 当前swiper索引
     * @return {*}
     * @Description: Do not Description
     */
    showPreviewDialog(index) {
      try {
        this.$refs.previewDialog?.mySwiper.slideTo(index);
        this.$refs.previewDialog.changeDialog();
      } catch (error) {
        throw new Error("预览弹窗" + error);
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-01 14:53:39
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:
     * @param {*} param
     * @return {*}
     * @Description: Do not Description
     */
    showPreviewVideoDialog(param) {
      try {
        this.$refs.previewVideoDialog?.mySwiper.slideTo(param);
        this.$refs.previewVideoDialog.changeDialog();
      } catch (error) {
        throw new Error("预览弹窗 video" + error);
      }
    },
    async getProductDetailInfo(type, productId) {
      console.log(productId);
      if (productId.length > 0) {
        let { code, data } = await this.$productsDetail({ id: productId[0] });
        if (code == 200000) {
          this.$refs[`${type}`].changeDialog();
          this.handleJump(data.product.urlPath);
        }
      }
      return;
    },
    /**
     * @Author: liudanli
     * @Date: 2023-06-27 09:37:31
     * @LastEditTime: 点赞
     * @description:
     * @param {*} item
     * @param {*} type
     * @return {*}
     */
    async changeLikeHandler(item, type) {
      try {
        let res = await this.$reviewItemLike(item.id);
        if (res.code == 200101) {
          this.$toast({ type: "fail", content: res.message });
        } else {
          // this.$set(item, "isLike", 1);
          if (type == "like") {
            item.isLike = 1;
            item.likeNum += 1;
          } else {
            item.isLike = 0;
            item.likeNum -= 1;
          }
        }
      } catch (error) {
        throw new Error("详情页点赞" + error);
      }
    },
  },
};
